html {
  background: #d1e2e8;
  overflow-x: hidden;
}

.App {
  background: #d1e2e8;
  min-height: 100vh;
  height: 100%;
  z-index: 99;
  overflow-x: hidden;
}

.cont {
  background: #ffffff;
  border-radius: 30px;
  padding-bottom: 40px;
}

header {
  padding: 15px 0;
}

.flatpickr-input {
  border-radius: 10px;
  border: 1px solid #e6ebf1;
  height: 56px;
  width: 100%;
  padding: 10px;
}

.day {
  border-radius: 10px;
  border: 1px solid #e6ebf1;
  background: var(--form-bgr);
  height: 56px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
  justify-content: center;
  width: 100%;
}

.title-date {
  color: #1a3353;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-bottom: 10px;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.title-date i {
  color: #e6ebf1;
}

.time {
  display: flex;
  height: 56px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid #e6ebf1;
  background: var(--form-bgr);
  width: 100%;
}

.row {
  row-gap: 1.5rem;
}

.result-info {
  border-radius: 10px;
  border: 1px solid #e6ebf1;
  padding: 20px;
  margin-bottom: 15px;
}

.result button,
.admin-filter button {
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  color: #fff;
  border-radius: 10px;
  background: var(--main-color);
}

footer {
  padding: 20px;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: #0e6c94;
}

button:hover {
  background: #edf4f7;
  border: 1px solid rgb(14 108 148 / 40%);
  transform: scale(1.01);
  box-shadow: 0 20px 25px -5px #0000000a, 0 8px 10px -6px #00000010;
}

.text-lead {
  color: #72849a;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
}

.number {
  padding: 3px 5px;
  letter-spacing: 1px;
  border-radius: 5px;
  background: #e6f0f4;
  margin-top: 10px;
}

.title {
  color: #1a3353;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
}

.rent .active {
  border: 1px solid #0e6c94 !important;
  background: #e5f4ff !important;
}

button:disabled,
button[disabled] {
  cursor: not-allowed;
  pointer-events: none;
  border: 1px solid #d0d4d7;
  background: #d0d4d7;
}

button:disabled .title {
  color: #72849a !important;
}

.result-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
}

.result-info .text-lead {
  padding-bottom: 5px;
}

#uncontrolled-tab-example-tabpane-home.active,
#uncontrolled-tab-example-tabpane-profile.active {
  border: 0 !important;
  background: none !important;
}

.nav-tabs .active {
  border: 0 !important;
  background: #fff !important;
}

.tabmain {
  margin-bottom: 0px !important;
  display: flex;
  justify-content: flex-end;
  margin-top: -45px;
}

.nav-link:focus,
.nav-link:hover {
  color: #0e6c94 !important;
}

.nav-link {
  color: #0f6c9473 !important;
}

.tab-content {
  z-index: 99;
  position: relative;
}

.nav-tabs {
  color: #1a3353;
  font-weight: 400;
  border-radius: 20px;
}

.nav-tabs .nav-link.active {
  color: #1a3353 !important;
  font-weight: 500;
}

.nav-tabs .nav-link {
  border: none !important;
}

section {
  position: relative;
}

.popup {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.popup button {
  margin-top: -10px;
  color: #0e6c94;
}

.popup button:hover {
  border: none;
  background: transparent;
  color: #000;
}

.inform {
  font-size: 14px;
  color: red;
  line-height: 14px;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: transparent !important;
}

.ts .number {
  margin: 0;
}

.mb0 {
  margin-bottom: 0px !important;
}

i {
  line-height: 0;
}

.nav-pills .nav-link {
  display: flex !important;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  padding-left: 0 !important;
  color: #0f6c9473 !important;
  border-radius: 0 !important;
  margin-right: -1px;
  font-weight: 500;
}

.nav-pills .nav-link.active {
  color: #0e6c94 !important;
  border-right: 1px solid #0e6c94;
  border-radius: 0;
}

.nav-link i {
  font-size: 22px;
}

.exit.nav-link {
  color: #ff1f1f !important;
  font-size: 14px !important;
  margin-top: 30px;
}

.exit.nav-link i {
  font-size: 18px;
}

.flex-column {
  flex-direction: column !important;
  border-right: 1px solid #e6ebf1;
  height: 100%;
  gap: 10px;
}

.exit {
  cursor: pointer;
}

.result button:hover,
.admin-filter button:hover {
  border: 0;
  background: var(--main-color-hover);
}

.nav-tabs .nav-link {
  font-size: 15px;
  padding: 10px 20px;
  line-height: 15px;
}

.loader svg {
  height: 100px;
}

.info-rent {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

.info-rent i {
  font-size: 55px;
  color: #0f6c94;
}

.admin-filter {
  display: flex;
  gap: 10px;
  padding-bottom: 25px;
}

select.form-select {
  border-radius: 10px;
  border: 1px solid #e6ebf1;
  color: #1a3353;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  cursor: pointer;
  background: transparent;
}

.form-select:focus,
.admin-filter input:focus {
  border: 1px solid #0e6c94 !important;
  box-shadow: 0 0 0 0rem rgba(13, 110, 253, 0.25) !important;
}

.form-select:hover,
.admin-filter input:hover {
  border: 1px solid #0e6c94 !important;
}

.loader svg {
  width: 100%;
}

.offline {
  width: 100%;
  height: 100%;
  position: absolute;
  background: #fff;
  z-index: 999;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.offline-app {
  display: none;
}

.mt-0 {
  margin-top: 0 !important;
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: ' ';
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #0e6c94;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.offline-info {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  gap: 20px;
  font-weight: 700;
}

.App .message {
  margin: 0px 15px;
  padding: 15px;
  border-radius: 25px;
  box-shadow: none;
}

section {
  padding: 55px 0px 10px 0px;
}

.w100 {
  width: 100%;
}

input.input-result,
input.react-datalist-input__textbox,
textarea.textarea-result {
  border: 1px solid #e6ebf1;
  width: 100%;
  height: 56px;
  color: #1a3353;
  font-size: 16px;
  padding: 10px 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  border-radius: 10px;
}

input.input-result:hover,
input.react-datalist-input__textbox:hover,
textarea.textarea-result:hover {
  background: #edf4f7;
  border: 1px solid rgb(14 108 148 / 40%);
}

input.input-result::placeholder,
input.react-datalist-input__textbox::placeholder,
textarea.textarea-result::placeholder {
  font-size: 14px;
  color: #72849a;
  font-weight: 400;
}

input.input-result:focus,
input.react-datalist-input__textbox:focus,
textarea.textarea-result:focus {
  border: 1px solid #0e6c94 !important;
}

.result-comment {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.textarea-result {
  min-height: 90px;
}

.result-comment .text-lead {
  padding-left: 20px;
}

button.btn.btn-primary {
  padding: 12px 20px;
  border-radius: 10px;
  background: var(--main-color);
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  border: none;
}

button.btn.btn-secondary {
  padding: 12px 20px;
  border-radius: 10px;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  border: none;
}

.accordion-item button.accordion-button {
  border-radius: 5px;
}

.ts {
  width: 100%;
}

.accordion-item {
  border: 0 !important;
}

.accordion-button:not(.collapsed) {
  color: #1a3353 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  background-color: #d1e2e8 !important;
  box-shadow: none !important;
}

.accordion {
  width: 100% !important;
}

.accordion-body ul,
.accordion-body li {
  list-style: unset !important;
  margin-bottom: 0 !important;
}

.accordion-body {
  padding-left: 0 !important;
}

.accordion-date {
  padding-left: 2rem;
}
