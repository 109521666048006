.overlap {
  width: 60px;
  height: 60px;
  background-color: #0e6c94;
  border-radius: 50%;
}

.element {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  gap: 10px;
  padding-bottom: 20px;
  padding-top: 40px;
}

.h-2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  justify-content: center;
  color: #fff;
}

.text-wrapper {
  font-size: 30px;
  font-weight: 600;
}

.h-2 .span {
  font-size: 14px;
  font-weight: 400;
}

.text-wrapper-2 {
  font-size: 21px;
  font-weight: 600;
  line-height: 28px;
  color: #1a3353;
}

.text-wrapper-2-info {
  font-size: 13px;
  font-weight: 300;
  line-height: 13px;
  color: #72849a;
}
