@media screen and (max-width: 768px) {
  .login-page-wrapper {
    max-width: 350px;
  }

  .logo-text div {
    font-size: 14px;
  }

  .tabmain {
    margin-top: 0px;
    justify-content: space-between;
    position: fixed;
    box-shadow: 0 10px 60px 0 rgba(90, 116, 148, 0.4);
    border-radius: 25px 25px 0 0;
    bottom: 0;
    left: 0;
    box-sizing: border-box;
    right: 0;
    z-index: 999;
    margin: 0 auto;
    background-color: #fff;
    min-height: 70px;
    display: flex;
    text-align: center;
    align-items: center;
    flex-wrap: nowrap;
  }

  .home-page:before,
  .profile-page:before,
  .admin-page:before,
  .about-page:before {
    font-size: 28px;
  }

  .nav-tabs .nav-link {
    font-size: 0px;
    padding: 0px;
  }

  .nav-item {
    color: rgb(15 108 148);
    position: relative;
    text-decoration: none;
    transition: all 0.5s ease;
  }

  .nav-item button:hover {
    background: #fff;
  }

  .overlap {
    width: 30px;
    height: 30px;
    background-color: #0e6c94;
    border-radius: 10px;
  }

  .type {
    border-radius: 10px;
    padding: 10px;
    flex-wrap: nowrap;
    align-items: flex-start;
    text-align: left;
    flex-direction: column-reverse;
    gap: 5px;
  }

  .text-lead {
    color: #72849a;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 10px;
  }

  .title {
    color: #1a3353;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }

  .h-2 .span {
    font-size: 10px;
    font-weight: 400;
  }

  .text-wrapper-2 {
    font-size: 16px;
    line-height: 18px;
  }

  .cars,
  .flatpickr-input,
  .result-info {
    padding: 10px;
  }

  .text-wrapper {
    font-size: 14px;
  }

  .type-icon {
    width: 45px;
  }

  .nav-pills .nav-link {
    font-size: 14px;
  }

  header {
    display: none;
  }

  section {
    padding: 10px 10px 60px 10px;
  }

  .container,
  .container-fluid,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    --bs-gutter-x: 3.5rem;
  }

  .result button,
  .admin-filter button {
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
  }

  .title-date {
    font-size: 10px;
  }

  select.form-select {
    font-size: 14px;
    height: 56px;
  }

  .admin-filter {
    flex-direction: column;
  }

  .accordion-body {
    font-size: 12px;
  }
}
