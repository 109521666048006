.login-page {
  background: var(--main-bgr);
  width: 100%;
  height: 100vh;
  /* height: 100%; */
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-page-wrapper {
  display: flex;
  max-width: 390px;
  width: 100%;
  padding: 40px;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  background: #fff;
  border-radius: 30px;
}

.login-page-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.login-page-form label {
  color: var(--main-text);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.login-page-form label span {
  color: #ff6b72;
}

.login-page-form input {
  border-radius: 10px;
  border: 1px solid #e6ebf1;
  background: #fff;
  height: 40px;
  padding: 8.5px 12px;
}

.login-page-form input::placeholder {
  color: rgba(114, 132, 154, 0.4);
  font-size: 14px;
}

.login-page-form input:focus,
.login-page-form input:hover {
  border: 1px solid var(--main-color);
}

.login-page-alert {
  border-radius: 10px;
  border: 1px #ffe7e6;
  background: #fff1f0;
  color: var(--main-text);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  padding: 10px;
}

.login-page-inp {
  flex-direction: column;
  gap: 5px;
  display: flex;
}

.login-page-form button,
.result-info button {
  padding: 12px 20px;
  border-radius: 10px;
  background: var(--main-color);
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin-top: 15px;
}

.login-page-form button:hover,
.result-info button:hover {
  background: var(--main-color-hover);
  border: 0 !important;
}

#email::placeholder {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14' fill='none'%3E%3Cg clip-path='url(%23clip0_47841_21)'%3E%3Cpath d='M13.5 1.49715H0.5C0.223437 1.49715 0 1.72059 0 1.99715V11.9972C0 12.2737 0.223437 12.4972 0.5 12.4972H13.5C13.7766 12.4972 14 12.2737 14 11.9972V1.99715C14 1.72059 13.7766 1.49715 13.5 1.49715ZM12.875 3.2284V11.3722H1.125V3.2284L0.69375 2.89247L1.30781 2.1034L1.97656 2.62372H12.025L12.6938 2.1034L13.3078 2.89247L12.875 3.2284V3.2284ZM12.025 2.62215L7 6.5284L1.975 2.62215L1.30625 2.10184L0.692188 2.8909L1.12344 3.22684L6.46094 7.37684C6.61444 7.49609 6.80328 7.56083 6.99766 7.56083C7.19203 7.56083 7.38088 7.49609 7.53438 7.37684L12.875 3.2284L13.3062 2.89247L12.6922 2.1034L12.025 2.62215Z' fill='%230E6C94'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_47841_21'%3E%3Crect width='14' height='14' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E")
    no-repeat center left;
  padding-left: 23px;
}

#password::placeholder {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14' fill='none'%3E%3Cpath d='M12.0001 6.25189H10.9376V2.75189C10.9376 1.6472 10.0422 0.751892 8.93756 0.751892H5.06256C3.95787 0.751892 3.06256 1.6472 3.06256 2.75189V6.25189H2.00006C1.7235 6.25189 1.50006 6.47533 1.50006 6.75189V12.7519C1.50006 13.0285 1.7235 13.2519 2.00006 13.2519H12.0001C12.2766 13.2519 12.5001 13.0285 12.5001 12.7519V6.75189C12.5001 6.47533 12.2766 6.25189 12.0001 6.25189ZM4.18756 2.75189C4.18756 2.26908 4.57975 1.87689 5.06256 1.87689H8.93756C9.42037 1.87689 9.81256 2.26908 9.81256 2.75189V6.25189H4.18756V2.75189ZM11.3751 12.1269H2.62506V7.37689H11.3751V12.1269ZM6.56256 9.95502V10.7831C6.56256 10.8519 6.61881 10.9081 6.68756 10.9081H7.31256C7.38131 10.9081 7.43756 10.8519 7.43756 10.7831V9.95502C7.56654 9.86242 7.66281 9.73128 7.71252 9.58048C7.76222 9.42968 7.76279 9.267 7.71415 9.11585C7.66551 8.96471 7.57017 8.83289 7.44185 8.73938C7.31352 8.64588 7.15884 8.5955 7.00006 8.5955C6.84128 8.5955 6.6866 8.64588 6.55828 8.73938C6.42995 8.83289 6.33461 8.96471 6.28597 9.11585C6.23733 9.267 6.2379 9.42968 6.28761 9.58048C6.33731 9.73128 6.43358 9.86242 6.56256 9.95502V9.95502Z' fill='%230E6C94'/%3E%3C/svg%3E")
    no-repeat center left;
  padding-left: 23px;
}
