.logo {
  display: flex;
  gap: 15px;
}

.logo-text {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.logo-text div {
  color: var(--main-text);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  text-transform: uppercase;
}

.logo-text span {
  color: var(--main-text);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
}
