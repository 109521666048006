@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800&display=swap');
@import url('~@flaticon/flaticon-uicons/css/regular/rounded');

:root {
  --main-color: #0e6c94;
  --main-text: #1a3353;
  --main-bgr: #d1e2e8;
  --form-bgr: #fff;

  --main-color-hover: rgb(12 107 147 / 80%);
}

html {
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

html::-webkit-scrollbar {
  width: 0px; /* For Chrome, Safari, and Opera */
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  padding: 0px;
  margin: 0px;
  border: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Links */

a,
a:link,
a:visited {
  text-decoration: none;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

a:hover {
  text-decoration: none;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

/* Common */

aside,
nav,
footer,
header,
section,
main {
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-size: inherit;
  font-weight: inherit;
}

ul,
ul li {
  list-style: none;
}

img {
  vertical-align: top;
}

img,
svg {
  max-width: 100%;
  height: auto;
}

address {
  font-style: normal;
}

/* Form */

input,
textarea,
button,
select,
.accordion-button {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  background-color: transparent;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

input::-ms-clear {
  display: none;
}

button,
input[type='submit'] {
  display: inline-block;
  box-shadow: none;
  background-color: transparent;
  background: none;
  cursor: pointer;
}

input:focus,
input:active,
button:focus,
button:active,
select:focus,
select:active,
button.accordion-button:active,
button.accordion-button:focus {
  outline: none !important;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

textarea:focus,
input:focus,
.accordion-button:focus {
  outline: 0;
}

*:focus {
  outline: 0 !important;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

label {
  cursor: pointer;
}

legend {
  display: block;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:active,
input:-webkit-autofill:focus {
  background-color: #e6ebf1 !important;
  color: #555 !important;
  -webkit-box-shadow: 0 0 0 1000px #e6ebf1 inset !important;
  -webkit-text-fill-color: #555555 !important;
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  --bs-gutter-x: 5.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

.home-page:before,
.profile-page:before,
.admin-page:before,
.about-page:before {
  font-family: uicons-regular-rounded !important;
  font-style: normal;
  font-weight: 400 !important;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  top: 2px;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  font-size: 16px;
}

.home-page:before {
  content: '\f59d';
}

.profile-page:before {
  content: '\f32e';
}

.admin-page::before {
  content: '\f27c';
}

.about-page::before {
  content: '\f5d0';
}

.modal-content {
  border-radius: 25px;
}

.cont.container:before {
  font-family: uicons-regular-rounded !important;
  font-style: normal;
  font-weight: 400 !important;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  top: 30px;
  right: -20px;
  line-height: 1;
  position: relative;
  font-size: 160px;
  position: absolute;
  z-index: 0;
  color: #d1e1e840;
  overflow: hidden;
}

.cont {
  position: relative;
}
