.type {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding: 20px;
  text-align: left;
  gap: 10px;
  border: 1px solid #e6ebf1;
  border-radius: 10px;
  cursor: pointer;
  justify-content: space-between;
  width: 100%;
}

.type-icon img {
  width: 58px;
  height: 58px;
  flex-shrink: 0;
}

.type.active .type-icon svg,
.type.active .type-icon i {
  opacity: 1;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.type .type-icon svg,
.type .type-icon i {
  opacity: 0.1;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.type:hover svg,
.type:hover i {
  opacity: 0.2;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.type i {
  font-size: 51px;
  position: relative;
  top: 4px;
  color: #0e6c94;
}
